import * as React from "react";
import { Dictionary } from "../../../clay/common";
import { propCheck } from "../../../clay/propCheck";
import { QuickCacheApi } from "../../../clay/quick-cache";
import { RawFileWidget } from "../../../clay/widgets/file-widget";
import { FormField } from "../../../clay/widgets/FormField";
import {
    RecordWidget,
    subStatus,
    subvalidate,
    ValidationError,
    WidgetAction,
    WidgetContext,
    WidgetExtraProps,
    WidgetProps,
    WidgetResult,
    WidgetState,
    WidgetStatus,
} from "../../../clay/widgets/index";
import { TextWidget } from "../../../clay/widgets/TextWidget";
import { slugCheckValidate } from "../../slugCheck";
import { Document, DOCUMENT_META } from "./table";

//!RecordWidget
export type DocumentWidgetData = Document;

export const DocumentWidgetFields = {
    slug: TextWidget,
    file: RawFileWidget,
};

export function validateDocumentWidget(data: Document, cache: QuickCacheApi) {
    const errors = baseValidateDocumentWidget(data, cache);

    slugCheckValidate("Document", data, cache, errors);

    return errors;
}

export function DocumentWidgetComponent(
    widgets: DocumentWidgetWidgets,
    props: DocumentWidgetProps
) {
    return <></>;
}

// BEGIN MAGIC -- DO NOT EDIT
type DocumentWidgetContext = WidgetContext<typeof DocumentWidgetFields.slug> &
    WidgetContext<typeof DocumentWidgetFields.file>;
type DocumentWidgetExtraProps = {};
type DocumentWidgetBaseState = {
    slug: WidgetState<typeof DocumentWidgetFields.slug>;
    file: WidgetState<typeof DocumentWidgetFields.file>;
};
export type DocumentWidgetState = DocumentWidgetBaseState;

type BaseDocumentWidgetAction =
    | { type: "SLUG"; action: WidgetAction<typeof DocumentWidgetFields.slug> }
    | { type: "FILE"; action: WidgetAction<typeof DocumentWidgetFields.file> };

export type DocumentWidgetAction = BaseDocumentWidgetAction;

export type DocumentWidgetProps = WidgetProps<
    DocumentWidgetState,
    DocumentWidgetData,
    DocumentWidgetAction,
    DocumentWidgetExtraProps
>;

function baseValidateDocumentWidget(
    data: DocumentWidgetData,
    cache: QuickCacheApi
) {
    const errors: ValidationError[] = [];
    subvalidate(DocumentWidgetFields.slug, data.slug, cache, "slug", errors);
    subvalidate(DocumentWidgetFields.file, data.file, cache, "file", errors);
    return errors;
}
function baseDocumentWidgetReduce(
    state: DocumentWidgetState,
    data: DocumentWidgetData,
    action: BaseDocumentWidgetAction,
    context: DocumentWidgetContext
): WidgetResult<DocumentWidgetState, DocumentWidgetData> {
    let subcontext = context;
    switch (action.type) {
        case "SLUG": {
            const inner = DocumentWidgetFields.slug.reduce(
                state.slug,
                data.slug,
                action.action,
                subcontext
            );
            return {
                state: { ...state, slug: inner.state },
                data: { ...data, slug: inner.data },
            };
        }
        case "FILE": {
            const inner = DocumentWidgetFields.file.reduce(
                state.file,
                data.file,
                action.action,
                subcontext
            );
            return {
                state: { ...state, file: inner.state },
                data: { ...data, file: inner.data },
            };
        }
    }
}
export type DocumentWidgetReactContextType = {
    state: DocumentWidgetState;
    data: DocumentWidgetData;
    dispatch: (action: DocumentWidgetAction) => void;
    status: WidgetStatus;
};
export const DocumentWidgetReactContext = React.createContext<
    DocumentWidgetReactContextType | undefined
>(undefined);
export const DocumentWidgetWidgets = {
    slug: function (
        props: WidgetExtraProps<typeof DocumentWidgetFields.slug> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            DocumentWidgetReactContext
        ) as DocumentWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof DocumentWidgetFields.slug>) =>
                context.dispatch({ type: "SLUG", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "slug", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <DocumentWidgetFields.slug.component
                state={context.state.slug}
                data={context.data.slug}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "Slug"}
            />
        );
    },
    file: function (
        props: WidgetExtraProps<typeof DocumentWidgetFields.file> & {
            label?: string;
            readOnly?: boolean;
        }
    ) {
        const context = React.useContext(
            DocumentWidgetReactContext
        ) as DocumentWidgetReactContextType;
        const subdispatch = React.useCallback(
            (action: WidgetAction<typeof DocumentWidgetFields.file>) =>
                context.dispatch({ type: "FILE", action }),
            [context.dispatch]
        );
        const status = React.useMemo(
            () => subStatus(context.status, "file", !!props.readOnly),
            [context.status, props.readOnly]
        );
        return (
            <DocumentWidgetFields.file.component
                state={context.state.file}
                data={context.data.file}
                dispatch={subdispatch}
                status={status}
                {...props}
                label={props.label || "File"}
            />
        );
    },
};
export const DocumentWidget: RecordWidget<
    DocumentWidgetState,
    DocumentWidgetData,
    DocumentWidgetContext,
    DocumentWidgetAction,
    DocumentWidgetExtraProps
> = {
    reactContext: DocumentWidgetReactContext,
    fieldWidgets: DocumentWidgetWidgets,
    dataMeta: DOCUMENT_META,
    initialize(
        data: DocumentWidgetData,
        context: DocumentWidgetContext,
        parameters?: string[]
    ): WidgetResult<DocumentWidgetState, DocumentWidgetData> {
        let subparameters: Dictionary<string[]> = {};
        let subcontext = context;
        const innerSlug = DocumentWidgetFields.slug.initialize(
            data.slug,
            subcontext,
            subparameters.slug
        );
        const innerFile = DocumentWidgetFields.file.initialize(
            data.file,
            subcontext,
            subparameters.file
        );
        let state = {
            slug: innerSlug.state,
            file: innerFile.state,
        };
        return {
            state,
            data: {
                ...data,
                slug: innerSlug.data,
                file: innerFile.data,
            },
        };
    },
    validate: validateDocumentWidget,
    component: React.memo((props: DocumentWidgetProps) => {
        return (
            <DocumentWidgetReactContext.Provider value={props}>
                {DocumentWidgetComponent(DocumentWidgetWidgets, props)}
            </DocumentWidgetReactContext.Provider>
        );
    }, propCheck),
    reduce: baseDocumentWidgetReduce,
};

type DocumentWidgetWidgets = {
    slug: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof DocumentWidgetFields.slug
        >
    >;
    file: React.FunctionComponent<
        { label?: string; readOnly?: boolean } & WidgetExtraProps<
            typeof DocumentWidgetFields.file
        >
    >;
};
// END MAGIC -- DO NOT EDIT
