import dateParse from "date-fns/parseISO";
import { Decimal } from "decimal.js";
import { Quantity } from "../../../clay/common";
import { Link } from "../../../clay/link";
import { LocalDate } from "../../../clay/LocalDate";
import { RecordMeta } from "../../../clay/meta";
import { resolve } from "../../../clay/queryFuncs";
import { genUUID, UUID } from "../../../clay/uuid";
import { User } from "../../user/table";
import { Version } from "../../version";
import { AiAuthor } from "../authors/table";
import { AiCategory } from "../categories/table";
import { AiDiscipline } from "../disciplines/table";

//!Data
export type SurferSeoCompetitor = {
    url: string;
    contentScore: Quantity;
};

//!Data
export type AiArticle = {
    id: UUID;
    recordVersion: Version;

    title: string;
    shortTitle: string;
    content: string;

    byline: string;
    addedBy: Link<User>;
    addedDateTime: Date | null;
    modifiedBy: Link<User>;
    modifiedDateTime: Date | null;
    publishedDateTime: Date | null;
    modifiedDate: LocalDate | null;
    publishedDate: LocalDate | null;
    displayDates: boolean;
    priority: Quantity;
    tags: string[];

    trending: boolean;

    author: Link<User>;
    kind: "article" | "resource" | "inflection" | "interview";
    category: Link<AiCategory>;
    slug: string;
    excerpt: string;
    metaTitle: string;
    metaDescription: string;
    metaKeywords: string;
    focusKeywords: string;
    surferSeoAuditId: string;
    surferSeoAuditKeywords: string;
    surferSeoContentScore: Quantity | null;
    surferSeoCompetitors: SurferSeoCompetitor[];
    status: "DRAFT" | "SEO" | "READY" | "SUBSCRIBER" | "PUBLISHED";

    heroImageBannerUrl: string;
    heroImageBannerAltText: string;
    heroImageThumbnailUrl: string;
    heroImageThumbnailAltText: string;

    alternateSocialMediaImageUrl: string;
    alternateSocialMediaImageAltText: string;

    discipline: Link<AiDiscipline>;
    disciplines: Link<AiDiscipline>[];

    edDegreeLevel: string;
    edCategory: string;
    edSubcategory: string;

    authors: Link<AiAuthor>[];
    reviewers: Link<AiAuthor>[];

    hideAdWidget: boolean;
    hideSubscribePopup: boolean;
    hideExcerpt: boolean;
    importance: Quantity | null;

    relatedArticles: Link<AiArticle>[];

    cachedViews: Quantity;
};

function calcAiArticleEditorUrl(article: AiArticle) {
    return `https://editor.academicinfluence.com/#/ai/articles/edit/${article.id}`;
}

export function calcAiArticlePath(article: AiArticle): string {
    return article.slug.startsWith("/")
        ? article.slug
        : `${
              article.kind === "resource"
                  ? "/resources"
                  : article.kind === "article"
                  ? "/rankings"
                  : article.kind === "interview"
                  ? "/interviews"
                  : article.kind === "inflection"
                  ? "/inflection"
                  : "???"
          }/${resolve("category.slug") + "/"}${article.slug}`;
}

export function articlePath(
    article: AiArticle,
    category: AiCategory | null
): string {
    if (article.slug.startsWith("/")) {
        return article.slug;
    }
    return `${
        article.kind === "resource"
            ? "/resources"
            : article.kind === "article"
            ? "/rankings"
            : article.kind === "interview"
            ? "/interviews"
            : article.kind === "inflection"
            ? "/inflection"
            : "???"
    }/${category?.slug + "/"}${article.slug}`;
}

export function calcAiArticleLiveUrl(article: AiArticle): string {
    return "https://academicinfluence.com" + calcAiArticlePath(article);
}

export function articleLiveUrl(
    article: AiArticle,
    category: AiCategory | null
): string {
    return "https://academicinfluence.com" + articlePath(article, category);
}

// BEGIN MAGIC -- DO NOT EDIT
export type SurferSeoCompetitorJSON = {
    url: string;
    contentScore: string;
};

export function JSONToSurferSeoCompetitor(
    json: SurferSeoCompetitorJSON
): SurferSeoCompetitor {
    return {
        url: json.url,
        contentScore: new Decimal(json.contentScore),
    };
}
export type SurferSeoCompetitorBrokenJSON = {
    url?: string;
    contentScore?: string;
};

export function newSurferSeoCompetitor(): SurferSeoCompetitor {
    return JSONToSurferSeoCompetitor(repairSurferSeoCompetitorJSON(undefined));
}
export function repairSurferSeoCompetitorJSON(
    json: SurferSeoCompetitorBrokenJSON | undefined
): SurferSeoCompetitorJSON {
    if (json) {
        return {
            url: json.url || "",
            contentScore: json.contentScore || "0",
        };
    } else {
        return {
            url: undefined || "",
            contentScore: undefined || "0",
        };
    }
}

export function SurferSeoCompetitorToJSON(
    value: SurferSeoCompetitor
): SurferSeoCompetitorJSON {
    return {
        url: value.url,
        contentScore: value.contentScore.toString(),
    };
}

export const SURFER_SEO_COMPETITOR_META: RecordMeta<
    SurferSeoCompetitor,
    SurferSeoCompetitorJSON,
    SurferSeoCompetitorBrokenJSON
> = {
    name: "SurferSeoCompetitor",
    type: "record",
    repair: repairSurferSeoCompetitorJSON,
    toJSON: SurferSeoCompetitorToJSON,
    fromJSON: JSONToSurferSeoCompetitor,
    fields: {
        url: { type: "string" },
        contentScore: { type: "quantity" },
    },
    userFacingKey: null,
    expressionFunctions: {},
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

export type AiArticleJSON = {
    id: string;
    recordVersion: number | null;
    title: string;
    shortTitle: string;
    content: string;
    byline: string;
    addedBy: string | null;
    addedDateTime: string | null;
    modifiedBy: string | null;
    modifiedDateTime: string | null;
    publishedDateTime: string | null;
    modifiedDate: string | null;
    publishedDate: string | null;
    displayDates: boolean;
    priority: string;
    tags: string[];
    trending: boolean;
    author: string | null;
    kind: string;
    category: string | null;
    slug: string;
    excerpt: string;
    metaTitle: string;
    metaDescription: string;
    metaKeywords: string;
    focusKeywords: string;
    surferSeoAuditId: string;
    surferSeoAuditKeywords: string;
    surferSeoContentScore: string | null;
    surferSeoCompetitors: SurferSeoCompetitorJSON[];
    status: string;
    heroImageBannerUrl: string;
    heroImageBannerAltText: string;
    heroImageThumbnailUrl: string;
    heroImageThumbnailAltText: string;
    alternateSocialMediaImageUrl: string;
    alternateSocialMediaImageAltText: string;
    discipline: string | null;
    disciplines: (string | null)[];
    edDegreeLevel: string;
    edCategory: string;
    edSubcategory: string;
    authors: (string | null)[];
    reviewers: (string | null)[];
    hideAdWidget: boolean;
    hideSubscribePopup: boolean;
    hideExcerpt: boolean;
    importance: string | null;
    relatedArticles: (string | null)[];
    cachedViews: string;
};

export function JSONToAiArticle(json: AiArticleJSON): AiArticle {
    return {
        id: { uuid: json.id },
        recordVersion: { version: json.recordVersion },
        title: json.title,
        shortTitle: json.shortTitle,
        content: json.content,
        byline: json.byline,
        addedBy: json.addedBy,
        addedDateTime:
            json.addedDateTime !== null ? dateParse(json.addedDateTime) : null,
        modifiedBy: json.modifiedBy,
        modifiedDateTime:
            json.modifiedDateTime !== null
                ? dateParse(json.modifiedDateTime)
                : null,
        publishedDateTime:
            json.publishedDateTime !== null
                ? dateParse(json.publishedDateTime)
                : null,
        modifiedDate:
            json.modifiedDate !== null
                ? LocalDate.parse(json.modifiedDate)
                : null,
        publishedDate:
            json.publishedDate !== null
                ? LocalDate.parse(json.publishedDate)
                : null,
        displayDates: json.displayDates,
        priority: new Decimal(json.priority),
        tags: json.tags.map((inner) => inner),
        trending: json.trending,
        author: json.author,
        kind: json.kind as any,
        category: json.category,
        slug: json.slug,
        excerpt: json.excerpt,
        metaTitle: json.metaTitle,
        metaDescription: json.metaDescription,
        metaKeywords: json.metaKeywords,
        focusKeywords: json.focusKeywords,
        surferSeoAuditId: json.surferSeoAuditId,
        surferSeoAuditKeywords: json.surferSeoAuditKeywords,
        surferSeoContentScore:
            json.surferSeoContentScore !== null
                ? new Decimal(json.surferSeoContentScore)
                : null,
        surferSeoCompetitors: json.surferSeoCompetitors.map((inner) =>
            JSONToSurferSeoCompetitor(inner)
        ),
        status: json.status as any,
        heroImageBannerUrl: json.heroImageBannerUrl,
        heroImageBannerAltText: json.heroImageBannerAltText,
        heroImageThumbnailUrl: json.heroImageThumbnailUrl,
        heroImageThumbnailAltText: json.heroImageThumbnailAltText,
        alternateSocialMediaImageUrl: json.alternateSocialMediaImageUrl,
        alternateSocialMediaImageAltText: json.alternateSocialMediaImageAltText,
        discipline: json.discipline,
        disciplines: json.disciplines.map((inner) => inner),
        edDegreeLevel: json.edDegreeLevel,
        edCategory: json.edCategory,
        edSubcategory: json.edSubcategory,
        authors: json.authors.map((inner) => inner),
        reviewers: json.reviewers.map((inner) => inner),
        hideAdWidget: json.hideAdWidget,
        hideSubscribePopup: json.hideSubscribePopup,
        hideExcerpt: json.hideExcerpt,
        importance:
            json.importance !== null ? new Decimal(json.importance) : null,
        relatedArticles: json.relatedArticles.map((inner) => inner),
        cachedViews: new Decimal(json.cachedViews),
    };
}
export type AiArticleBrokenJSON = {
    id?: string;
    recordVersion?: number | null;
    title?: string;
    shortTitle?: string;
    content?: string;
    byline?: string;
    addedBy?: string | null;
    addedDateTime?: string | null;
    modifiedBy?: string | null;
    modifiedDateTime?: string | null;
    publishedDateTime?: string | null;
    modifiedDate?: string | null;
    publishedDate?: string | null;
    displayDates?: boolean;
    priority?: string;
    tags?: string[];
    trending?: boolean;
    author?: string | null;
    kind?: string;
    category?: string | null;
    slug?: string;
    excerpt?: string;
    metaTitle?: string;
    metaDescription?: string;
    metaKeywords?: string;
    focusKeywords?: string;
    surferSeoAuditId?: string;
    surferSeoAuditKeywords?: string;
    surferSeoContentScore?: string | null;
    surferSeoCompetitors?: SurferSeoCompetitorJSON[];
    status?: string;
    heroImageBannerUrl?: string;
    heroImageBannerAltText?: string;
    heroImageThumbnailUrl?: string;
    heroImageThumbnailAltText?: string;
    alternateSocialMediaImageUrl?: string;
    alternateSocialMediaImageAltText?: string;
    discipline?: string | null;
    disciplines?: (string | null)[];
    edDegreeLevel?: string;
    edCategory?: string;
    edSubcategory?: string;
    authors?: (string | null)[];
    reviewers?: (string | null)[];
    hideAdWidget?: boolean;
    hideSubscribePopup?: boolean;
    hideExcerpt?: boolean;
    importance?: string | null;
    relatedArticles?: (string | null)[];
    cachedViews?: string;
};

export function newAiArticle(): AiArticle {
    return JSONToAiArticle(repairAiArticleJSON(undefined));
}
export function repairAiArticleJSON(
    json: AiArticleBrokenJSON | undefined
): AiArticleJSON {
    if (json) {
        return {
            id: json.id || genUUID(),
            recordVersion:
                json.recordVersion === undefined ? null : json.recordVersion,
            title: json.title || "",
            shortTitle: json.shortTitle || "",
            content: json.content || "",
            byline: json.byline || "",
            addedBy: json.addedBy || null,
            addedDateTime: json.addedDateTime || null,
            modifiedBy: json.modifiedBy || null,
            modifiedDateTime: json.modifiedDateTime || null,
            publishedDateTime: json.publishedDateTime || null,
            modifiedDate: json.modifiedDate || null,
            publishedDate: json.publishedDate || null,
            displayDates: json.displayDates || false,
            priority: json.priority || "0",
            tags: (json.tags || []).map((inner) => inner || ""),
            trending: json.trending || false,
            author: json.author || null,
            kind: json.kind || "article",
            category: json.category || null,
            slug: json.slug || "",
            excerpt: json.excerpt || "",
            metaTitle: json.metaTitle || "",
            metaDescription: json.metaDescription || "",
            metaKeywords: json.metaKeywords || "",
            focusKeywords: json.focusKeywords || "",
            surferSeoAuditId: json.surferSeoAuditId || "",
            surferSeoAuditKeywords: json.surferSeoAuditKeywords || "",
            surferSeoContentScore: json.surferSeoContentScore || null,
            surferSeoCompetitors: (
                json.surferSeoCompetitors || []
            ).map((inner) => repairSurferSeoCompetitorJSON(inner)),
            status: json.status || "DRAFT",
            heroImageBannerUrl: json.heroImageBannerUrl || "",
            heroImageBannerAltText: json.heroImageBannerAltText || "",
            heroImageThumbnailUrl: json.heroImageThumbnailUrl || "",
            heroImageThumbnailAltText: json.heroImageThumbnailAltText || "",
            alternateSocialMediaImageUrl:
                json.alternateSocialMediaImageUrl || "",
            alternateSocialMediaImageAltText:
                json.alternateSocialMediaImageAltText || "",
            discipline: json.discipline || null,
            disciplines: (json.disciplines || []).map((inner) => inner || null),
            edDegreeLevel: json.edDegreeLevel || "",
            edCategory: json.edCategory || "",
            edSubcategory: json.edSubcategory || "",
            authors: (json.authors || []).map((inner) => inner || null),
            reviewers: (json.reviewers || []).map((inner) => inner || null),
            hideAdWidget: json.hideAdWidget || false,
            hideSubscribePopup: json.hideSubscribePopup || false,
            hideExcerpt: json.hideExcerpt || false,
            importance: json.importance || null,
            relatedArticles: (json.relatedArticles || []).map(
                (inner) => inner || null
            ),
            cachedViews: json.cachedViews || "0",
        };
    } else {
        return {
            id: undefined || genUUID(),
            recordVersion: null,
            title: undefined || "",
            shortTitle: undefined || "",
            content: undefined || "",
            byline: undefined || "",
            addedBy: undefined || null,
            addedDateTime: undefined || null,
            modifiedBy: undefined || null,
            modifiedDateTime: undefined || null,
            publishedDateTime: undefined || null,
            modifiedDate: undefined || null,
            publishedDate: undefined || null,
            displayDates: undefined || false,
            priority: undefined || "0",
            tags: (undefined || []).map((inner) => inner || ""),
            trending: undefined || false,
            author: undefined || null,
            kind: undefined || "article",
            category: undefined || null,
            slug: undefined || "",
            excerpt: undefined || "",
            metaTitle: undefined || "",
            metaDescription: undefined || "",
            metaKeywords: undefined || "",
            focusKeywords: undefined || "",
            surferSeoAuditId: undefined || "",
            surferSeoAuditKeywords: undefined || "",
            surferSeoContentScore: undefined || null,
            surferSeoCompetitors: (undefined || []).map((inner) =>
                repairSurferSeoCompetitorJSON(inner)
            ),
            status: undefined || "DRAFT",
            heroImageBannerUrl: undefined || "",
            heroImageBannerAltText: undefined || "",
            heroImageThumbnailUrl: undefined || "",
            heroImageThumbnailAltText: undefined || "",
            alternateSocialMediaImageUrl: undefined || "",
            alternateSocialMediaImageAltText: undefined || "",
            discipline: undefined || null,
            disciplines: (undefined || []).map((inner) => inner || null),
            edDegreeLevel: undefined || "",
            edCategory: undefined || "",
            edSubcategory: undefined || "",
            authors: (undefined || []).map((inner) => inner || null),
            reviewers: (undefined || []).map((inner) => inner || null),
            hideAdWidget: undefined || false,
            hideSubscribePopup: undefined || false,
            hideExcerpt: undefined || false,
            importance: undefined || null,
            relatedArticles: (undefined || []).map((inner) => inner || null),
            cachedViews: undefined || "0",
        };
    }
}

export function AiArticleToJSON(value: AiArticle): AiArticleJSON {
    return {
        id: value.id.uuid,
        recordVersion: value.recordVersion.version,
        title: value.title,
        shortTitle: value.shortTitle,
        content: value.content,
        byline: value.byline,
        addedBy: value.addedBy,
        addedDateTime:
            value.addedDateTime !== null
                ? value.addedDateTime.toISOString()
                : null,
        modifiedBy: value.modifiedBy,
        modifiedDateTime:
            value.modifiedDateTime !== null
                ? value.modifiedDateTime.toISOString()
                : null,
        publishedDateTime:
            value.publishedDateTime !== null
                ? value.publishedDateTime.toISOString()
                : null,
        modifiedDate:
            value.modifiedDate !== null ? value.modifiedDate.toString() : null,
        publishedDate:
            value.publishedDate !== null
                ? value.publishedDate.toString()
                : null,
        displayDates: value.displayDates,
        priority: value.priority.toString(),
        tags: value.tags.map((inner) => inner),
        trending: value.trending,
        author: value.author,
        kind: value.kind,
        category: value.category,
        slug: value.slug,
        excerpt: value.excerpt,
        metaTitle: value.metaTitle,
        metaDescription: value.metaDescription,
        metaKeywords: value.metaKeywords,
        focusKeywords: value.focusKeywords,
        surferSeoAuditId: value.surferSeoAuditId,
        surferSeoAuditKeywords: value.surferSeoAuditKeywords,
        surferSeoContentScore:
            value.surferSeoContentScore !== null
                ? value.surferSeoContentScore.toString()
                : null,
        surferSeoCompetitors: value.surferSeoCompetitors.map((inner) =>
            SurferSeoCompetitorToJSON(inner)
        ),
        status: value.status,
        heroImageBannerUrl: value.heroImageBannerUrl,
        heroImageBannerAltText: value.heroImageBannerAltText,
        heroImageThumbnailUrl: value.heroImageThumbnailUrl,
        heroImageThumbnailAltText: value.heroImageThumbnailAltText,
        alternateSocialMediaImageUrl: value.alternateSocialMediaImageUrl,
        alternateSocialMediaImageAltText:
            value.alternateSocialMediaImageAltText,
        discipline: value.discipline,
        disciplines: value.disciplines.map((inner) => inner),
        edDegreeLevel: value.edDegreeLevel,
        edCategory: value.edCategory,
        edSubcategory: value.edSubcategory,
        authors: value.authors.map((inner) => inner),
        reviewers: value.reviewers.map((inner) => inner),
        hideAdWidget: value.hideAdWidget,
        hideSubscribePopup: value.hideSubscribePopup,
        hideExcerpt: value.hideExcerpt,
        importance:
            value.importance !== null ? value.importance.toString() : null,
        relatedArticles: value.relatedArticles.map((inner) => inner),
        cachedViews: value.cachedViews.toString(),
    };
}

export const AI_ARTICLE_META: RecordMeta<
    AiArticle,
    AiArticleJSON,
    AiArticleBrokenJSON
> = {
    name: "AiArticle",
    type: "record",
    repair: repairAiArticleJSON,
    toJSON: AiArticleToJSON,
    fromJSON: JSONToAiArticle,
    fields: {
        id: { type: "uuid" },
        recordVersion: { type: "version" },
        title: { type: "string" },
        shortTitle: { type: "string" },
        content: { type: "string" },
        byline: { type: "string" },
        addedBy: { type: "uuid", linkTo: "User" },
        addedDateTime: { type: "datetime" },
        modifiedBy: { type: "uuid", linkTo: "User" },
        modifiedDateTime: { type: "datetime" },
        publishedDateTime: { type: "datetime" },
        modifiedDate: { type: "date" },
        publishedDate: { type: "date" },
        displayDates: { type: "boolean" },
        priority: { type: "quantity" },
        tags: { type: "array", items: { type: "string" } },
        trending: { type: "boolean" },
        author: { type: "uuid", linkTo: "User" },
        kind: {
            type: "enum",
            values: ["article", "resource", "inflection", "interview"],
        },
        category: { type: "uuid", linkTo: "AiCategory" },
        slug: { type: "string" },
        excerpt: { type: "string" },
        metaTitle: { type: "string" },
        metaDescription: { type: "string" },
        metaKeywords: { type: "string" },
        focusKeywords: { type: "string" },
        surferSeoAuditId: { type: "string" },
        surferSeoAuditKeywords: { type: "string" },
        surferSeoContentScore: { type: "quantity?" },
        surferSeoCompetitors: {
            type: "array",
            items: SURFER_SEO_COMPETITOR_META,
        },
        status: {
            type: "enum",
            values: ["DRAFT", "SEO", "READY", "SUBSCRIBER", "PUBLISHED"],
        },
        heroImageBannerUrl: { type: "string" },
        heroImageBannerAltText: { type: "string" },
        heroImageThumbnailUrl: { type: "string" },
        heroImageThumbnailAltText: { type: "string" },
        alternateSocialMediaImageUrl: { type: "string" },
        alternateSocialMediaImageAltText: { type: "string" },
        discipline: { type: "uuid", linkTo: "AiDiscipline" },
        disciplines: {
            type: "array",
            items: { type: "uuid", linkTo: "AiDiscipline" },
        },
        edDegreeLevel: { type: "string" },
        edCategory: { type: "string" },
        edSubcategory: { type: "string" },
        authors: { type: "array", items: { type: "uuid", linkTo: "AiAuthor" } },
        reviewers: {
            type: "array",
            items: { type: "uuid", linkTo: "AiAuthor" },
        },
        hideAdWidget: { type: "boolean" },
        hideSubscribePopup: { type: "boolean" },
        hideExcerpt: { type: "boolean" },
        importance: { type: "quantity?" },
        relatedArticles: {
            type: "array",
            items: { type: "uuid", linkTo: "AiArticle" },
        },
        cachedViews: { type: "quantity" },
    },
    userFacingKey: "title",
    expressionFunctions: {
        editorUrl: calcAiArticleEditorUrl,
        path: calcAiArticlePath,
        liveUrl: calcAiArticleLiveUrl,
    },
    expressionKeyedFunctions: {},
    expressionKeyedFunctionTypes: {},
};

// END MAGIC -- DO NOT EDIT
